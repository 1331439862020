const pageTypes = {
    'country_select': 'country_select',
    'earn': 'earn',
    'ref': 'ref',
    'task': 'task',
    'boost': 'boost',
    'stats': 'stats',
    'loading': 'loading'
}

export default pageTypes