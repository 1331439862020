let countries = [
  'China',
  'India',
  'United States of America',
  'Indonesia',
  "Congo",
  'Pakistan',
  'Nigeria',
  'Brazil',
  'Bangladesh',
  'Russia',
  'Mexico',
  'Japan',
  'Philippines',
  'Ethiopia',
  'Egypt',
  'Vietnam',
  'Turkey',
  'Iran',
  'Germany',
  'France',
  'United Kingdom',
  'Thailand',
  'South Africa',
  'Tanzania',
  'Italy',
  'Myanmar',
  'Colombia',
  'Kenya',
  'South Korea',
  'Spain',
  'Argentina',
  'Uganda',
  'Algeria',
  'Iraq',
  'Sudan',
  'Canada',
  'Poland',
  'Morocco',
  'Uzbekistan',
  'Ukraine',
  'Angola',
  'Afghanistan',
  'Peru',
  'Malaysia',
  'Mozambique',
  'Saudi Arabia',
  'Austria',
  'Sweden',
  'Belarus',
];
countries = countries.sort()


export default countries
